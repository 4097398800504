import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle, description, links}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to='/'>{siteTitle}</Link>
      </div>

      <div className={styles.services}>
        {description.map((service, i) => (
          <li key={i}>
              {service}
          </li>
        ))}
      </div>

      <div className={styles.links}>
        <div className={styles.link}><Link 
        to='/about'
        activeStyle={{
          textDecoration:'underline'
        }}>
          About</Link></div>
        {links.map((link, i) => (
          <>
            <div className={styles.link}> / </div>
            <div className={styles.link}><a key={i} href={link.url} target='blank'>
            {link.title}
            </a></div>
          </>
        ))}
      </div>

      {/* <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
      </button> */}

      {/* <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to='/archive/'>Archive</Link>
          </li>
        </ul>
      </nav> */}
    </div>
  </div>
)

export default Header
